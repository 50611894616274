<ng-container *ngIf="positionRight !== null">
    <div [class.slide-over-displayed]="slideOverDisplayed" class="slide-over__overlay"
         (click)="slideOverStateChange.emit(!slideOverDisplayed);">
    </div>
    <div [class.slide-over-displayed]="slideOverDisplayed"
         [ngClass]="{
        'slide-over-displayed': slideOverDisplayed,
        'slide-over-large': slideOverLarge,
        'slide-over-right': positionRight
    }" class="slide-over">
        <div class="slide-over__scroll-container">
            <button class="slide-over__close-button" type="button" (click)="slideOverStateChange.emit(!slideOverDisplayed);">
                <span class="icon-fermer"></span>
            </button>
            <div class="slide-over__logo">
                <app-logo></app-logo>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>
