<app-slide-over [slideOverDisplayed]="cartOverDisplayed" [slideOverLarge]="true" [positionRight]="true" (slideOverStateChange)="cartOverStateChange.emit($event)">
    <div class="cart-over container">
        <div class="cart-over__title">
            <span class="main-title">Panier</span>
        </div>
        <ng-container *ngIf="globalCartService.$cart | async as cart; else emptyCart">
            <ng-container *ngIf="cart.items?.length > 0; else emptyCart">
                <div *ngFor="let item of cart.items" class="cart-over__item">
                    <app-cart-product [product]="item"></app-cart-product>
                </div>
                <div>
                    <a mat-stroked-button class="btn btn-blue cart-over__button" [class]="validateButtonGoogleAdsTrackingClass"
                       routerLink="/panier/validation" (click)="cartOverStateChange.emit(false)">Valider mon panier</a>
                    <button
                        mat-stroked-button
                        class="btn btn-border cart-over__button btn-tracking-cart-overlay"
                        (click)="cartOverStateChange.emit(false)"
                    >Continuer mes achats</button>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #emptyCart>
            <div class="cart-over__empty-message">
                <span>Votre panier est vide !</span>
            </div>
        </ng-template>
    </div>
<!--    <app-cart-recommendation></app-cart-recommendation>-->
</app-slide-over>
