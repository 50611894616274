import {
    Component,
    EventEmitter,
    Input,
    Output,
    Inject,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core';
import { DOCUMENT, Location, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatAnchor, MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { SlideOverComponent } from '../slide-over/slide-over.component';
import { ICart } from '../../interfaces';
import { GlobalCartService, UtilsService } from '../../services';
import { CartProductComponent } from '../../../shared/modules/cart-shared/components';

@Component({
    selector: 'app-cart-over',
    templateUrl: './cart-over.component.html',
    styleUrls: ['./cart-over.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SlideOverComponent,
        NgIf,
        NgFor,
        CartProductComponent,
        MatAnchor,
        RouterLink,
        MatButton,
        AsyncPipe,
    ],
})
export class CartOverComponent implements OnChanges, OnInit {
    @Input() public cartOverDisplayed: boolean;
    @Output() public cartOverStateChange: EventEmitter<boolean> = new EventEmitter();

    public validateButtonGoogleAdsTrackingClass: string;

    constructor(
        @Inject(DOCUMENT) private document: any,
        public readonly globalCartService: GlobalCartService,
        public readonly utilsService: UtilsService,
        private location: Location,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'cartOverDisplayed')) {
            this.cartOverDisplayState();
        }
    }

    public ngOnInit(): void {
        if (!this.utilsService.onBrowser()) {
            return;
        }

        this.globalCartService.getCart(true).subscribe();

        this.globalCartService.combineCart().subscribe((cart: ICart) => {
            this.validateButtonGoogleAdsTrackingClass = 'btn-tracking-cart-overlay ';

            if (cart?.items) {
                const brandsToTrack: string[] = [];
                for (const cartItem of cart.items) {
                    this.validateButtonGoogleAdsTrackingClass += `btn-tracking-${cartItem.slug} `;

                    if (!brandsToTrack.includes(cartItem.brandSlug)) {
                        brandsToTrack.push(cartItem.brandSlug);
                    }
                }

                for (const brandToTrack of brandsToTrack) {
                    this.validateButtonGoogleAdsTrackingClass += `btn-tracking-${brandToTrack} `;
                }
            }

            this.validateButtonGoogleAdsTrackingClass += 'btn-tracking-magazine';
            this.changeDetectorRef.markForCheck();
        });
    }

    private cartOverDisplayState(): void {
        this.document.body.style.overflowY = this.cartOverDisplayed ? 'hidden' : 'auto';
    }
}
