import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { filter } from 'rxjs/operators';
import { NgFor } from '@angular/common';

import { SlideOverComponent } from '../slide-over/slide-over.component';
import { UtilsService, AuthService } from '../../services';
import { INavbar } from '../../interfaces';
import menuListJson from '../../../../assets/json/navbar-list.json';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandState', [
            state('false', style({
                height: '0px',
            })),
            transition('true <=> false', [
                animate('0.15s')
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        SlideOverComponent,
        NgFor,
        RouterLink,
    ]
})
export class NavbarComponent {
    @Input() public navbarDisplayed: boolean;
    @Output() public navbarStateChange: EventEmitter<boolean> = new EventEmitter();

    public expandMenu = false;
    public menuList: INavbar[] = menuListJson;

    constructor(
        private readonly router: Router,
        private readonly utilsService: UtilsService,
        public readonly authService: AuthService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        if (utilsService.onBrowser()) {
            router.events
                .pipe(filter((e: any) => e instanceof NavigationEnd && this.navbarDisplayed))
                .subscribe(() => {
                    this.navbarStateChange.emit(false);
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    public clickOnLink(): void {
        if (this.navbarDisplayed && this.utilsService.onMobile()) {
            this.navbarStateChange.emit(false);
        }
    }
}
