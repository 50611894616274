import { Routes } from '@angular/router';
import { LayoutComponent, LoaderFullpageComponent } from './core/components';

export const routes: Routes = [
    {
        path: 'reader/:magazineId',
        component: LoaderFullpageComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/reader/reader.routes').then((m: any) => m.readerRoutes)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '404',
                loadChildren: () => import('./modules/no-page-found/no-page-found.routes').then(
                    (m: any) => m.noPageFoundRoutes)
            },
            {
                path: 'compte',
                loadChildren: () => import('./modules/account/account.routes').then((m: any) => m.accountRoutes)
            },
            {
                path: 'panier',
                loadChildren: () => import('./modules/cart/cart.routes').then((m: any) => m.cartRoutes)
            },
            {
                path: 'contact',
                loadChildren: () => import('./modules/contact/contact.routes').then((m: any) => m.contactRoutes)
            },
            {
                path: 'connexion',
                loadChildren: () => import('./modules/connection/connection.routes').then((m: any) => m.connectionRoutes)
            },
            {
                path: 'liseuse-numerique',
                loadChildren: () => import('./modules/reader-tutorial/reader-tutorial.routes').then(
                    (m: any) => m.readerTutorialRoutes)
            },
            {
                path: 'mon-offre',
                loadChildren: () => import('./modules/my-offer/my-offer.routes').then((m: any) => m.myOfferRoutes)
            },
            {
                path: 'newsletters',
                loadChildren: () => import('./modules/newsletter/newsletter.routes').then((m: any) => m.newsletterRoutes)
            },
            {
                path: 'operations-speciales/:opSlug',
                pathMatch: 'full',
                loadChildren: () => import('./modules/special-operations/special-operations.routes').then(
                    (m: any) => m.specialOperationsRoutes
                )
            },
            {
                path: 'page/:pageSlug',
                pathMatch: 'full',
                loadChildren: () => import('./modules/editable-page/editable-page.routes').then(
                    (m: any) => m.editablePageRoutes)
            },
            {
                path: ':brandSlug',
                pathMatch: 'full',
                loadChildren: () => import('./modules/brand/brand.routes').then((m: any) => m.brandRoutes)
            },
            {
                path: ':brandSlug/abonnements/:subscriptionSlug',
                loadChildren: () => import('./modules/subscription-single/subscription-single.routes')
                    .then((m: any) => m.subscriptionSingleRoutes)
            },
            {
                path: ':brandSlug/:magazineSlug',
                pathMatch: 'full',
                loadChildren: () => import('./modules/magazine/magazine.routes').then((m: any) => m.magazineRoutes)
            },
            {
                path: '',
                loadChildren: () => import('./modules/home/home.routes').then((m: any) => m.homeRoutes)
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
