import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';

@Component({
    selector: 'app-slide-over',
    templateUrl: './slide-over.component.html',
    styleUrls: ['./slide-over.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        LogoComponent,
    ],
})
export class SlideOverComponent {
    @Input() public slideOverDisplayed: boolean;
    @Input() public slideOverLarge = false;
    @Input() public positionRight = false;
    @Output() public slideOverStateChange: EventEmitter<boolean> = new EventEmitter();
}
