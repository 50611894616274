import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
    enableProdMode();
}

if (environment.sentry) {
    Sentry.init({
        dsn: environment.sentry,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [environment.frontUrl],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    });
}

document.addEventListener('DOMContentLoaded', (): void => {
    // eslint-disable-next-line @typescript-eslint/typedef
    bootstrapApplication(AppComponent, appConfig).catch(e => {
        console.error(e);
    });
});
