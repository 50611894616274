import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { CartOverComponent } from '../cart-over/cart-over.component';
import { GlobalCartService, UtilsService } from '../../services';
import { LoaderComponent } from '../../../shared/modules/loader/components';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HeaderComponent,
        NavbarComponent,
        CartOverComponent,
        RouterOutlet,
        NgIf,
        LoaderComponent,
        FooterComponent,
    ],
})
export class LayoutComponent {
    public navbarDiplayed = false;

    constructor(
        public readonly globalCartService: GlobalCartService,
        public readonly utilsService: UtilsService,
    ) {
    }

    public changeNavbarState(navbarDiplayed: boolean): void {
        this.navbarDiplayed = navbarDiplayed;
    }

    public changeCartOverState(cartOverDisplayed: boolean): void {
        this.globalCartService.cartOverDisplayed = cartOverDisplayed;
    }
}
