import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterOutlet,
} from '@angular/router';
import { NgIf } from '@angular/common';

import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GlobalCartService, UtilsService } from './core/services';
import { LoaderComponent } from './shared/modules/loader/components';

declare let dataLayer: any[];

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        NgIf,
        RouterOutlet,
        LoaderComponent,
    ],
})
export class AppComponent implements OnInit {
    public pathname: string;
    public readerPage: number;

    constructor(
        public readonly globalCartService: GlobalCartService,
        public readonly utilsService: UtilsService,
        private readonly router: Router,
        private readonly seoMeta: Meta,
    ) {
    }

    public ngOnInit(): void {
        this.seoMeta.addTag({
            property: 'og:url',
            content: environment.frontUrl,
        });

        if (this.utilsService.onBrowser()) {
            this.pathname = window.location.pathname;
            this.readerPage = this.pathname.search('reader');

            if (this.pathname !== '/panier/validation' &&
                localStorage.getItem('subscription-cart-hash')) {
                this.globalCartService.removeCart();
            }

            this.router.events
                .pipe(filter((event: Event) => {
                    return event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel ||
                        event instanceof NavigationError;
                }))
                .subscribe((event: Event) => {
                    if (event instanceof NavigationStart) {
                        this.utilsService.scrollToTop();
                        this.utilsService.globalLoaderDisplay = true;
                        dataLayer.push({ 'event': 'page-change' });
                        return;
                    }

                    this.utilsService.globalLoaderDisplay = false;
                });
        }
    }
}
