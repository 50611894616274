import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UtilsService } from '../services';

@Injectable()
export class HttpTransferStateInterceptorService implements HttpInterceptor {
    constructor(private transferState: TransferState, private readonly utilsService: UtilsService) {
    }

    /*
    * When this method is execute on server-side : store all GET request response in transferState
    */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method !== 'GET') {
            return next.handle(request);
        }

        // The stateKey is the key we gonna use for store the request content in transferState
        // We use request url like stateKey
        const stateKey: StateKey<string> = makeStateKey<string>(request.url);

        // We check if this method is executed during the server-side rendering or on navigator
        if (!this.utilsService.onBrowser()) {
            // We are on server-side so we call the request and we store the response in transferState
            return next.handle(request).pipe(
                tap((event: HttpResponse<any>) => {
                    this.transferState.set(stateKey, event.body);
                })
            );
        }
        const stateResponse: any = this.transferState.get(stateKey, null);

        // We are on navigator so we check if the request response has already store in transferState by the server
        if (!stateResponse) {
            // We don't have stateResponse so we juste call normally the request
            return next.handle(request);
        }

        // We receive the request content store in transferState and we send it like an http response
        const httpStateResponse: any = new HttpResponse({ body: stateResponse, status: 200 });
        // We remove the transferState (like is it a cache) of this request because for the next navigator request...
        // ... we need the last data
        this.transferState.remove(stateKey);
        return of(httpStateResponse);
    }
}
