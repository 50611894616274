<header id="header" class="header">
    <div class="header__container" [class.header__container--reduce]="reduceHeader" [class.header__container--show-mobile]="showMobileHeader">
        <div class="header__left">
            <div>
                <button class="header__menu-button" type="button" (click)="navbarStateChange.emit(!navbarDisplayed);">
                    <span class="icon-menu"></span>
                </button>
            </div>
<!--            <div class="header__search">-->
<!--                <label>-->
<!--                    <input class="search__field" type="text" placeholder="Rechercher un magazine">-->
<!--                </label>-->
<!--                <button type="submit">-->
<!--                    <span class="icon-recherche"></span>-->
<!--                </button>-->
<!--            </div>-->
        </div>
        <div class="header__center">
            <app-logo></app-logo>
        </div>
        <div class="header__right">
            <div class="header__right--top">
                <a routerLink="/newsletters" class="header__right--top-text">Newsletters</a>
                <a routerLink="/mon-offre" class="header__right--top-text">Mon offre</a>
                <a routerLink="/connexion">
                    <span class="header__right--top-text">{{ authService.getAuthToken() ? 'Mon compte' : 'Se connecter' }}</span>
                    <span class="icon-connexion header__right--top-icons header__right--login-icon" aria-hidden="true"></span>
                </a>
                <button class="header__cart" (click)="cartOverStateChange.emit(!cartOverDisplayed);">
                    <span class="icon-panier header__right--top-icons" aria-hidden="true"></span>
                    <ng-container *ngIf="globalCartService.$cart | async as cart">
                        <span *ngIf="cart?.items?.length" class="header__cart-counter">{{ cart.items.length }}</span>
                    </ng-container>
                </button>
            </div>
<!--            <div class="header__right&#45;&#45;bottom" >-->
<!--                <a mat-stroked-button class="btn" href="#">Se réabonner</a>-->
<!--            </div>-->
        </div>
    </div>
</header>
