import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, tap, catchError, concatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ApiService, UtilsService, AuthService } from '../services';

@Injectable()
export class HttpTokenInterceptorService implements HttpInterceptor {
    constructor(
        private readonly apiService: ApiService,
        private readonly utilsService: UtilsService,
        private readonly transferState: TransferState,
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            request.url.search('oauth/token') !== -1 ||
            request.url.search(environment.apiUrl) === -1 ||
            (this.utilsService.onBrowser() && this.transferState.get(makeStateKey<string>(request.url), null))
        ) {
            return next.handle(request);
        }

        if (!this.utilsService.onBrowser() || !localStorage.getItem('request-token')) {
            return this.setNewApiTokenToRequest(request, next);
        }

        const requestWithToken: HttpRequest<any> = request.clone({
            headers: request.headers.set('Authorization', localStorage.getItem('request-token'))
        });

        return next.handle(requestWithToken).pipe(
            catchError((httpErrorResponse: any)  => {
                if (httpErrorResponse.error.message === 'Unauthorized') {
                    return this.setNewApiTokenToRequest(request, next);
                }

                if (['user_jwt_invalid', 'user_jwt_expired'].includes(httpErrorResponse.error.message)) {
                    return this.checkUserTokenError(requestWithToken, next, httpErrorResponse);
                }

                return throwError(() => httpErrorResponse.error);
            })
        );
    }

    private checkUserTokenError(
        request: any,
        next: any,
        httpErrorResponse: any,
        apiTokenAlreadyReset: boolean = false): Observable<HttpEvent<any>> {

        // If user-access-token expire, need refresh with user-refresh-token
        if (httpErrorResponse.error.message === 'user_jwt_expired' && this.authService.getCookie('refresh_token')) {
            return this.setNewUserTokenToRequest(request, next, apiTokenAlreadyReset);
        }

        // If user-access-token is missing or not JWT
        // If user-access-cookie is missing of invalid
        // If user-access-token is not equal to user-access-cookie
        // If user-refresh-token is missing, invalid or expired
        this.authService.removeAuthCookies();

        // Route where we want to stay on the page if the token is wrong
        if (
            window.location.pathname !== '/panier/validation' &&
            window.location.pathname.search('/newsletters') === -1
        ) { // For the cart we redirect to the step 1
            this.router.navigate(['/connexion']).then();
        }

        return throwError(() => httpErrorResponse.error);
    }

    private setNewApiTokenToRequest(
        request: HttpRequest<any>,
        next: HttpHandler,
        userTokenAlreadyReset: boolean = false
    ): Observable<HttpEvent<any>> {
        return this.generateRequestToken()
            .pipe(
                concatMap((token: string) => {
                    const requestWithToken: HttpRequest<any> = request.clone({
                        headers: request.headers.set('Authorization', token)
                    });

                    return next.handle(requestWithToken).pipe(
                        catchError((httpErrorResponse: any) => {
                            // User token invalid or expired
                            if (
                                httpErrorResponse.error.message !== 'Unauthorized' &&
                                httpErrorResponse.error.message !== 'user_jwt_invalid' &&
                                httpErrorResponse.error.message !== 'user_jwt_expired'
                            ) {
                                return throwError(() => httpErrorResponse.error);
                            }

                            if (userTokenAlreadyReset) {
                                this.router.navigate(['/connexion']).then();
                                return throwError(() => httpErrorResponse.error);
                            }

                            return this.checkUserTokenError(
                                requestWithToken,
                                next,
                                httpErrorResponse,
                                true,
                            );
                        })
                    );
                })
            );
    }

    private generateRequestToken(): Observable<string> {
        return this.apiService
            .postRequest(
                'oauth/token',
                environment.apiTokenLogin,
            )
            .pipe(
                // eslint-disable-next-line @typescript-eslint/naming-convention
                map((token: { access_token: string }) => 'Bearer ' + token.access_token),
                tap((token: string) => {
                    if (this.utilsService.onBrowser()) {
                        localStorage.setItem('request-token', token);
                    }
                })
            );
    }

    private setNewUserTokenToRequest(
        request: HttpRequest<any>,
        next: HttpHandler,
        apiTokenAlreadyReset: boolean = false): Observable<HttpEvent<any>> {
        return this.refreshUserToken()
            .pipe(
                concatMap((token: string) => {
                    const requestWithUserToken: HttpRequest<any> = request.clone({
                        headers: request.headers
                            .set('user-access-token', token)
                            .set('user-access-cookie', this.authService.getCookie('check-user-access-token'))
                    });

                    return next.handle(requestWithUserToken).pipe(
                        catchError((httpErrorResponse: any) => {
                            if (
                                httpErrorResponse.error.message !== 'Unauthorized' &&
                                httpErrorResponse.error.message !== 'user_jwt_invalid' &&
                                httpErrorResponse.error.message !== 'user_jwt_expired'
                            ) {
                                return throwError(() => httpErrorResponse.error);
                            }

                            if (apiTokenAlreadyReset) {
                                this.router.navigate(['/404']).then();
                                return throwError(() => httpErrorResponse.error);
                            }

                            return this.setNewApiTokenToRequest(requestWithUserToken, next, true);
                        })
                    );
                })
            );
    }

    private refreshUserToken(): Observable<string> {
        return this.apiService
            .apiRequest(
                'get',
                'users/token-refresh',
                {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'user-refresh-token': this.authService.getCookie('refresh_token')
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                catchError((error: any) => {
                    this.router.navigate(['/connexion']).then();
                    return throwError(() => error);
                }),
                map((response: { body: { token: string }, headers: any }) => {
                    this.authService.setUserCookieAndToken(response.body.token, response.headers.get('cookie'));
                    return response.body.token;
                })
            );
    }
}
