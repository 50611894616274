<footer class="footer">
    <div class="footer__top">
        <div class="footer__top--item">
            <div class="footer__top--icon">
                <span class="icon-connexion"></span>
            </div>
            <div class="footer__top--title">
                <span>Mon compte</span>
            </div>
            <div>
                <ul>
                    <li class="footer__top--link-item"><a routerLink="/compte/coordonnées">Informations personnelles</a></li>
                    <li class="footer__top--link-item"><a routerLink="/compte/abonnements">Mes abonnements</a></li>
                    <li class="footer__top--link-item"><a routerLink="/compte/commandes">Mes commandes</a></li>
                    <li class="footer__top--link-item"><a routerLink="/compte/kiosque">Mon kiosque numérique</a></li>
                </ul>
            </div>
        </div>
        <div class="footer__top--item">
            <div class="footer__top--icon">
                <span class="icon-question"></span>
            </div>
            <div class="footer__top--title">
                <span>Vous avez une question ?</span>
            </div>
            <div>
                <ul>
                    <li class="footer__top--link-item"><a routerLink="/page/qui-sommes-nous">Qui sommes-nous ?</a></li>
                    <li class="footer__top--link-item"><a routerLink="/contact">Service client</a></li>
                    <li class="footer__top--link-item"><a routerLink="/liseuse-numerique">Votre magazine en version numérique</a></li>
                </ul>
            </div>
        </div>
        <div class="footer__top--item">
            <div class="footer__top--icon">
                <span class="icon-paiement-CB"></span>
            </div>
            <div class="footer__top--title">
                <span>Réglez en toute sécurité</span>
            </div>
            <div class="footer__top--cb-list">
                <div class="footer__top--cb">
                    <img src="/assets/images/logo-visa.png" alt="Logo Visa" />
                </div>
                <div class="footer__top--cb">
                    <img src="/assets/images/logo-mastercard.png" alt="Logo Mastercard" />
                </div>
                <div class="footer__top--cb">
                    <img src="/assets/images/logo-GIE-CB.png" alt="Logo GIE CB" />
                </div>
            </div>
        </div>
        <div class="footer__top--item">
            <div class="footer__top--icon">
                <span class="icon-officiel"></span>
            </div>
            <div class="footer__top--title">
                <span>Site officiel de l'éditeur</span>
            </div>
            <div class="footer__top--logo">
                <img src="/assets/images/logo-burda.png" alt="Logo de la marque Burda Bleu" />
            </div>
        </div>
    </div>
    <div class="footer__bottom">
        <ul class="footer__menu">
            <li><a routerLink="/page/mentions-legales">Mentions légales</a></li>
            <li><a routerLink="/page/cgv">Conditions Générales de ventes</a></li>
            <li><a routerLink="/page/politique-de-confidentialite">Politique de confidentialité</a></li>
            <li><a routerLink="/page/cgu">Conditions Générales d'Utilisation</a></li>
            <li><a title="Mes consentements" href="javascript:Didomi.preferences.show()">Mes consentements</a></li>
        </ul>
        <div class="footer__license">
            <p>© Le Kiosque Burda</p>
        </div>
    </div>
</footer>
