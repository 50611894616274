import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { UtilsService } from '../../services';
import { LoaderComponent } from '../../../shared/modules/loader/components/loader/loader.component';

@Component({
    selector: 'app-loader-fullpage',
    templateUrl: './loader-fullpage.component.html',
    styleUrls: ['./loader-fullpage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        LoaderComponent,
        RouterOutlet,
    ],
})
export class LoaderFullpageComponent {
    constructor(
        public readonly utilsService: UtilsService,
    ) {
    }
}
