
<nav class="navbar">
    <app-slide-over [slideOverDisplayed]="navbarDisplayed" (slideOverStateChange)="navbarStateChange.emit($event)">
<!--        <div class="navbar__search container">-->
<!--            <label>-->
<!--                <input class="search__field" type="text" placeholder="Rechercher un magazine">-->
<!--            </label>-->
<!--            <button type="submit">-->
<!--                <span class="icon-recherche"></span>-->
<!--            </button>-->
<!--        </div>-->
        <div class="navbar__container">
            <ul class="navbar__menu">
                <li class="navbar__menu-item">
                    <div class="navbar__menu-item-header" (click)="expandMenu = !expandMenu;">
                        <div class="navbar__menu-item-left">
                            <span class="navbar__menu-item-icon icon-magazine"></span>
                            <span class="navbar__menu-item-title">Magazines</span>
                        </div>
                        <div>
                            <span [class]="expandMenu ? 'icon-moins' : 'icon-plus'"></span>
                        </div>
                    </div>
                    <div [@expandState]="expandMenu" class="navbar__menu-item-content">
                        <ul class="navbar__menu navbar__menu--child">
                            <li *ngFor="let category of menuList">
                                <div class="navbar__menu-item-header" (click)="category.expanded = !category.expanded;">
                                    <div class="navbar__menu-item-left">
                                        <div class="navbar__menu-item-icon">
                                            <span class="icon-{{category.slug}}"></span>
                                        </div>
                                        <div>
                                            <span class="navbar__menu-item-title">{{category.name}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span [class]="category.expanded ? 'icon-moins' : 'icon-plus'"></span>
                                    </div>
                                </div>
                                <div [@expandState]="category.expanded" class="navbar__menu-item-content">
                                    <ul class="navbar__menu navbar__menu--last-child">
                                        <ng-container *ngFor="let brand of category.brands">
                                            <li (click)="clickOnLink()"><a [routerLink]="brand.slug">{{brand.name}}</a></li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="navbar__menu-item" (click)="clickOnLink()">
                    <a routerLink="/compte">
                        <div class="navbar__menu-item-header">
                            <div class="navbar__menu-item-left">
                                <span class="navbar__menu-item-icon icon-connexion"></span>
                                <span class="navbar__menu-item-title">{{ authService.getAuthToken() ? 'Mon compte' : 'Se connecter' }}</span>
                            </div>
                        </div>
                    </a>
                </li>
                <!--            <li class="navbar__menu-item">-->
                <!--                <a href="#">-->
                <!--                    <div class="navbar__menu-item-header">-->
                <!--                        <div class="navbar__menu-item-left">-->
                <!--                            <span class="navbar__menu-item-icon icon-reabonnement"></span>-->
                <!--                            <span class="navbar__menu-item-title">Se réabonner</span>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </a>-->
                <!--            </li>-->
                <li class="navbar__menu-item" (click)="clickOnLink()">
                    <a routerLink="/contact">
                        <div class="navbar__menu-item-header">
                            <div class="navbar__menu-item-left">
                                <span class="navbar__menu-item-icon icon-service-client"></span>
                                <span class="navbar__menu-item-title">Service client</span>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="navbar__menu-item" (click)="clickOnLink()">
                    <a routerLink="/mon-offre">
                        <div class="navbar__menu-item-header">
                            <div class="navbar__menu-item-left">
                                <span class="navbar__menu-item-icon icon-officiel"></span>
                                <span class="navbar__menu-item-title">Mon offre</span>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="navbar__menu-item navbar__menu-item--mobile" (click)="clickOnLink()">
                    <a routerLink="/newsletters">
                        <div class="navbar__menu-item-header">
                            <div class="navbar__menu-item-left">
                                <span class="navbar__menu-item-icon icon-mail4"></span>
                                <span class="navbar__menu-item-title">Newsletters</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </app-slide-over>
</nav>
