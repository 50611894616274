<app-header
    [navbarDisplayed]="navbarDiplayed"
    [cartOverDisplayed]="globalCartService.cartOverDisplayed"
    (navbarStateChange)="changeNavbarState($event)"
    (cartOverStateChange)="changeCartOverState($event)"
></app-header>
<app-navbar [navbarDisplayed]="navbarDiplayed" (navbarStateChange)="changeNavbarState($event)"></app-navbar>
<app-cart-over [cartOverDisplayed]="globalCartService.cartOverDisplayed" (cartOverStateChange)="changeCartOverState($event)"></app-cart-over>

<div class="layout__content">
    <div [class.layout__reduce-content]="utilsService.globalLoaderDisplay">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="utilsService.globalLoaderDisplay" class="layout__loader-container">
        <div class="layout__loader">
            <app-loader></app-loader>
        </div>
    </div>
</div>

<app-footer></app-footer>
