import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { UtilsService, GlobalCartService, AuthService } from '../../services';
import { LogoComponent } from '../logo/logo.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        LogoComponent,
        RouterLink,
        NgIf,
        AsyncPipe,
    ],
})
export class HeaderComponent {
    @Input() public navbarDisplayed: boolean;
    @Output() public navbarStateChange: EventEmitter<boolean> = new EventEmitter();
    @Input() public cartOverDisplayed: boolean;
    @Output() public cartOverStateChange: EventEmitter<boolean> = new EventEmitter();
    public reduceHeader = false;
    public showMobileHeader = false;
    private previousScrollPosition: number;

    constructor(
        private readonly utilsService: UtilsService,
        public readonly globalCartService: GlobalCartService,
        public readonly authService: AuthService,
    ) {
    }

    @HostListener('window:scroll', ['$event'])
    private onScroll(): void {
        this.reduceHeader = this.utilsService.onMobile() && window.scrollY > 120;

        if (this.utilsService.onMobile() && this.previousScrollPosition) {
            this.showMobileHeader = window.scrollY < this.previousScrollPosition;
        }

        this.previousScrollPosition = window.scrollY;
    }
}
